import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Button, Container, Flex, Link, Spacer, Text, VStack } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import SEO from "../components/seo";
import { randomLie } from '../lie-utils';

function IndexPage() {
  const nextLie = randomLie();

  return (
    <Container maxWidth="100%" p={0}>
      <SEO title="Home" />
      <VStack spacing="8">
        <Button as={GatsbyLink} to={`/lie/${nextLie.id}`}>More wisdom</Button>
        <Box>
          <Link as={GatsbyLink} to={`/lie/${nextLie.id}`}>
            <StaticImage
              placeholder="blurred"
              src="https://i.imgflip.com/584vni.jpg"
              alt="Trump lies" />
          </Link>
          <Flex>
            <Spacer />
            <Text fontSize="xs" color="gray.500">Image credit <Link href="https://nymag.com/intelligencer/2020/03/frank-rich-trump-lies-his-way-through-a-pandemic.html">New York Magazine <ExternalLinkIcon /></Link></Text>
          </Flex>
        </Box>
      </VStack>
    </Container>
  )
}

export default IndexPage
